<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="title">
          {{ bidInfo.name }}
        </div>
        <div class="cont">
          <div class="block">
            <p class="label">项目概况</p>
            <div class="block-info-box">
              <div class="block-info-item">
                <div>
                  <span class="info-title">招标方式</span>
                  <span>{{ bidInfo.biddingTypeName }}</span>
                </div>
                <div>
                  <span class="info-title">项目区域</span>
                  <span>{{ bidInfo.areaName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <p class="label">招标人</p>
            <div class="block-info-box">
              <div class="block-info-item">
                <div>
                  <span class="info-title">招标人</span>
                  <span>{{ bidInfo.biddingPeople }}</span>
                </div>
                <div>
                  <span class="info-title">地址</span>
                  <span>{{ bidInfo.biddingArea }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <p class="label">公告内容</p>
            <div class="block-info-box">
              <div class="block-info-item">
                <div>
                  <div v-html="bidInfo.info"></div>
                  <a
                    :href="bidInfo.servicePath + bidInfo.attachment"
                    :download="bidInfo.name"
                    @click=""
                    >{{
                      bidInfo.name + "." + bidInfo.attachment.split(".")[1]
                    }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sign-up">
          <a @click="wantSign">我要报名</a>
        </div>
      </div>
    </div>
    <Modal
      title="我要报名"
      v-model="signShow"
      :scrollable="true"
      @on-visible-change="modalShow"
    >
      <Form
        ref="biddingInfo"
        :model="biddingInfo"
        :rules="rules"
        :label-width="90"
      >
        <FormItem label="公司名字" prop="name">
          <Input style="width: 10rem" v-model="biddingInfo.name"></Input>
        </FormItem>
        <FormItem label="联系人" prop="linkman">
          <Input style="width: 10rem" v-model="biddingInfo.linkman"></Input>
        </FormItem>
        <FormItem label="联系电话" prop="linktel">
          <Input style="width: 10rem" v-model="biddingInfo.linktel"></Input>
        </FormItem>
        <FormItem label="招标名字" prop="biddingName">
          <Input style="width: 10rem" v-model="biddingInfo.biddingName"></Input>
        </FormItem>
        <FormItem label="报名表" prop="attachment">
          <upload-file
            ref="uploadResume"
            :max="1"
            v-model="biddingInfo.attachment"
          ></upload-file>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="loading" @click="submit">提交</Button>
        <Button @click="() => (this.signShow = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import uploadFile from "@components/upload/upload_file";
export default {
  components: {
    uploadFile,
  },
  name: "",
  data() {
    return {
      bidInfo: [],
      signShow: false,
      biddingInfo: {
        name: "",
        linkman: "",
        linktel: "",
        biddingName: "",
        attachment: "",
      },
      rules: {
        name: [{ required: true, message: "请填写公司名字" }],
        linkman: [{ required: true, message: "请填写联系人名字" }],
        linktel: [{ required: true, message: "请填写联系电话" }],
        biddingName: [{ required: true, message: "请填写招标名字" }],
        attachment: [
          { required: true, message: "请上传报名表", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    getInfo() {
      this.$post(this.$api.BIDDING.VIEW, {
        id: this.$route.query.bidId,
      }).then((res) => {
        this.bidInfo = res;
      });
    },
    wantSign() {
      this.signShow = true;
    },
    submit() {
      this.$refs.biddingInfo.validate().then((res) => {
        if (!res) return;
        this.loading = true;
        let params = { ...this.biddingInfo };
        params.attachment = this.biddingInfo.attachment.replace(
          /http:\/\/127.0.0.1:80\//g,
          ""
        );
        this.$post("tender/add", params)
          .then(() => {
            this.$Message.success("报名成功");
            this.signShow = false;
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.biddingInfo = {
        name: "",
        linkman: "",
        linktel: "",
        biddingName: "",
        attachment: "",
      };
      this.$refs.biddingInfo.resetFields();
      this.$refs.uploadResume.clearFiles();
    },
  },
  mounted() {
    this.getInfo();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      padding-top: 0.5rem;
      .title {
        margin-bottom: 0.5rem;
      }
      .cont {
        width: 100%;
        padding: 0.6rem;
        border: 0.04px solid #eee;
        border-top: 0.08px solid #0075e2;
        .block {
          // margin-bottom: 2rem;
          .label {
            border-bottom: 0.04rem solid #eee;
            padding: 0.4rem 0;
          }
          .block-info-box {
            margin: 0.8rem 0;
            .block-info-item {
              > div {
                padding: 0.2rem 0.8rem;
                .info-title {
                  padding-right: 0.8rem;
                  color: #999;
                }
                > a {
                  margin-top: 0.4rem;
                  display: block;
                }
              }
            }
          }
        }
      }
      .sign-up {
        display: flex;
        justify-content: center;
        margin-top: 0.8rem;
        > a {
          width: 5rem;
          background: #ee661b;
          display: block;
          text-align: center;
          line-height: 1.8rem;
          color: #fff;
          font-size: 0.64rem;
        }
      }
    }
  }
}
</style>